export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    REGION: "us-west-2",
    BUCKET: "us.poc.smupre.fileup"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://okmn68l64e.execute-api.us-west-2.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_tJcxbRM3F",
    APP_CLIENT_ID: "2ofdo5egtq9nlpm9s9uraqha2b",
    IDENTITY_POOL_ID: "us-west-2:b6ceb51a-1432-4ea1-8a4a-55859e06c6f1"
  }
};

